import React, { ReactElement } from 'react';

import { displayOrderType, displayDestination, determineIconByOrderType } from '../../../utils';

import { OrderType } from '../../../types';

import styles from './DetailsViewCardOrderInfo.css';

type Props = {
  orderType: OrderType;
  destination: string | null;
};

const DetailsViewCardOrderInfo = ({ orderType, destination }: Props): ReactElement => (
  <article className={styles.article}>
    <div className={styles.container}>
      {determineIconByOrderType({ orderType })}
      <div className={styles.content}>
        <p data-cy="pick-tour-order-type">{displayOrderType(orderType)}</p>
        {destination && <p data-cy="pick-tour-destination">{displayDestination({ orderType, destination })}</p>}
      </div>
    </div>
  </article>
);

export default DetailsViewCardOrderInfo;
