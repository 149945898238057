import { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { APP_PAGE_ORDER_LIST, BFF_PICK_TOURS, BFF_ROOT, BFF_V1, PICK_TOUR_PAGE_PICK_TOUR } from "../constants";
import mapAPIError from "../mapAPIError";
import { UpdateStatus, UpdateType } from "../redux/actions/activePickTour.types";
import { setAppPage } from "../redux/actions/appPage.actions";
import { setPickTourPage } from "../redux/actions/pickTourPage.actions";
import { createToast } from "../redux/actions/toasts.actions";
import { RootState } from "../redux/reducers/rootReducer";
import { ActivePickTourState } from "../redux/reducers/types";
import { mapPickTourDataToUpdatePickTourRequest } from "../redux/sagas/updatePickTour.mappers";
import { getConfigAuthValuesFromState } from "../redux/selectors";
import { APIError } from "../types";

/*
this custom hook was created in order to detect screen activity for picktours, if no activity is
detected it will kick out user from current picktour
*/ 
export default function (timeout: number) {

    const timer = useRef<ReturnType<typeof setTimeout>>();
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const configAuthValues = useSelector(getConfigAuthValuesFromState);
    const activePickTourFromState: ActivePickTourState = useSelector((state: RootState) => state.activePickTour);
    const updateType : UpdateType = "status"
    const updateStatus : UpdateStatus = {pickTourStatus: "OPEN", pickTourSubStatus: "PAUSED"}
    const payload = {updateType, updateStatus}

    // TODO: update to implement useFetch
    const restartTimer = () => {
        timer.current = setTimeout(async() => {
            try {
                const loginData = sessionStorage.getItem('loginData');
                const token = JSON.parse(loginData || "{}").access_token
                if (configAuthValues && activePickTourFromState && token){
                    const url = `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${activePickTourFromState.pickTour.id}`
                    const data = mapPickTourDataToUpdatePickTourRequest(payload, activePickTourFromState.data)
                    const requestOptions = {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` },
                        body: JSON.stringify(data)
                    };
                    const result = await(fetch(url, requestOptions))
                    const json = await result.json();
                    if (json.errors.length === 0){
                        dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_PICK_TOUR }));
                        dispatch(setAppPage({ name: APP_PAGE_ORDER_LIST }));
                    }
                    if (json.errors.length !== 0){
                        json.errors.forEach((error:APIError) => {
                            dispatch(createToast({ type: 'error', message: mapAPIError(error), error }))
                        });
                    }
                }
            } catch (error) {
                dispatch(createToast({ type: 'error', message: t('ERRORS.DEFAULT_ERROR_MESSAGE') }))
            }
        }, timeout);
    }

    const onActivity = useRef(() => {
        if (timer.current) clearTimeout(timer.current);
        restartTimer();
    });

    useEffect(() => {
        const currentOnInactive = onActivity.current;
        restartTimer();
        window.addEventListener('click', onActivity.current);
        window.addEventListener('keydown', onActivity.current);
        return () => {
            if (timer.current) clearTimeout(timer.current);
            window.removeEventListener('click', currentOnInactive);
            window.removeEventListener('keydown', currentOnInactive);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return onActivity.current
}