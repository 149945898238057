import React, { ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSession } from 'az-auth';

import { useTranslation } from 'react-i18next';
import NavMenu from '../../layout/NavMenu';
import StoreLocation from '../../pages/StoreLocation';
import ConfirmationScreen from '../../layout/ConfirmationScreen';

import { getDriverLogoffStatus, selectPickerStore } from '../../../redux/selectors';
import { getCompletedRecalls } from '../../../redux/actions/orders.actions';
import { setDriverLogoffId } from '../../../redux/actions/driverLogoff.actions';

import { determineDeviceType, getUID } from '../../../utils';

import { DESKTOP, CT40, TC75, USER_DATA, STORE_RECALL_DATA, LAST_LOGIN } from '../../../constants';

import { AppLogType, NavMenuTab, UserData } from '../../../types';

import styles from './Waffle.css';
import { sendLog } from '../../../redux/actions/log.actions';

const square: ReactElement = <span className={styles.square} />;

const row: ReactElement = (
  <span className={styles.row}>
    {square}
    {square}
    {square}
  </span>
);

type Props = {
  activeNavTab: NavMenuTab;
  storeInputValue: string;
  firstStoreID: string;
  hasOverstockOrder: boolean;
  isOverstockEnabled: boolean;
  setActiveNavTab: (value: SetStateAction<NavMenuTab>) => void;
  setStoreInputValue: (value: SetStateAction<string>) => void;
  setFirstStoreID: (value: SetStateAction<string>) => void;
  createOverstockPlaceholder: () => void;
  clearSelected: () => void;
};

const Waffle = ({
  activeNavTab,
  storeInputValue,
  firstStoreID,
  hasOverstockOrder,
  isOverstockEnabled,
  setActiveNavTab,
  setStoreInputValue,
  setFirstStoreID,
  createOverstockPlaceholder,
  clearSelected,
}: Props): ReactElement => {
  const [isOpened, setIsOpened] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const dispatch = useDispatch();

  const pickerStore = useSelector(selectPickerStore);
  const driverLogoffStatus = useSelector(getDriverLogoffStatus);

  const { t } = useTranslation();
  const translationRoot = 'WAFFLE_MENU';

  useEffect(() => {
    if (driverLogoffStatus !== null) {
      localStorage.removeItem(`${LAST_LOGIN}_${getUID() || ""}`);
      clearSession();
    }
  }, [driverLogoffStatus]);

  const handleOnClick = () => {
    setIsOpened(prevIsOpened => !prevIsOpened);
    setIsAnimating(prevIsAnimating => !prevIsAnimating);
  };

  const driverLogoff = () => {
    localStorage.removeItem(STORE_RECALL_DATA)
    const deviceType = determineDeviceType();
    if (deviceType === DESKTOP) {
      localStorage.removeItem(`${LAST_LOGIN}_${getUID() || ""}`);
      clearSession();
    }
    if (deviceType === CT40 || deviceType === TC75) {
      const userData = sessionStorage.getItem(USER_DATA);
      if (userData) {
        const parsedUserData: UserData = JSON.parse(userData);
        const { uid } = parsedUserData.claims;
        dispatch(setDriverLogoffId(uid));
      }
    }
  };

  const determineTab = () => {
    if (activeNavTab === 'storeLocation') {
      return (
        <StoreLocation
          storeInputValue={storeInputValue}
          firstStoreID={firstStoreID}
          setStoreInputValue={setStoreInputValue}
          setFirstStoreID={setFirstStoreID}
          setActiveNavTab={setActiveNavTab}
        />
      );
    }
    if (activeNavTab === 'generateOverstock') {
      return (
        <ConfirmationScreen
          confirmationText={t(`${translationRoot}.GENERATE_ORDER_CONFIRMATION`)}
          confirmationButtonText={t(`${translationRoot}.GENERATE_ORDER_BUTTON`)}
          handleConfirm={createOverstockPlaceholder}
        />
      );
    }
    return null;
  };

  return (
    <>
      <button type="button" onClick={handleOnClick} className={styles.button} data-cy="waffle-button">
        <span className={styles.container}>
          {row}
          {row}
          {row}
        </span>
      </button>
      {isOpened || isAnimating ? (
        <NavMenu
          isOpened={isOpened}
          navTabs={[
            {
              id: 'storeLocation',
              text: t(`${translationRoot}.STORE_LOCATION`),
              disabled: activeNavTab === 'storeLocation' || pickerStore.shouldPromptStoreSelection,
              hasArrow: true,
              onClick: () => {
                handleOnClick();
                setActiveNavTab('storeLocation');
              },
            },
            {
              id: 'generateOverstock',
              text: t(`${translationRoot}.GENERATE_OVERSTOCK`),
              disabled:
                !isOverstockEnabled ||
                !pickerStore.storeID ||
                activeNavTab === 'generateOverstock' ||
                activeNavTab === 'storeLocation' ||
                hasOverstockOrder,
              hasArrow: true,
              onClick: () => {
                handleOnClick();
                setActiveNavTab('generateOverstock');
              },
            },
            {
              id: 'recallsHistory',
              text: t(
                `${translationRoot}.${
                  activeNavTab === 'recallsHistory' ? 'RETURN_TO_PENDING_ORDERS' : 'RECALLS_HISTORY'
                }`
              ),
              disabled:
                !pickerStore.storeID || activeNavTab === 'generateOverstock' || activeNavTab === 'storeLocation',
              onClick: () => {
                clearSelected();
                handleOnClick();
                if (activeNavTab === 'recallsHistory') {
                  setActiveNavTab(null);
                } else {

                  dispatch(getCompletedRecalls({ fulfillmentStoreID: pickerStore.storeID || '', daysAgo: 90, pageNumber:0 }));
                  setActiveNavTab('recallsHistory');
                }
              },
            },
            {
              id: 'logOut',
              text: t(`${translationRoot}.LOGOUT`),
              onClick: () => {
                handleOnClick();
                driverLogoff();
                dispatch(sendLog({ logEvent: AppLogType.LOG_OUT }));
              },
            },
       
          ]}
          handleOnClick={handleOnClick}
          setIsAnimating={setIsAnimating}
        />
      ) : null}
      {determineTab()}
    </>
  );
};

export default Waffle;
