import { useSelector } from 'react-redux';
import { getConfigAuthValuesFromState } from "../redux/selectors";

/*
This custom hook is made to perform service calls with timeout w/o involving redux sagas,
in order to avoid creating a saga for components that do no require to mutate global state
implement this hook
*/
export default function () {
  const loginData = sessionStorage.getItem('loginData') || "{}"
  const token = JSON.parse(loginData).access_token
  const configAuthValues = useSelector(getConfigAuthValuesFromState);

  async function get(url: string) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), configAuthValues?.FETCH_TIMEOUT || 9000);
    try {
      const response = await fetch(url, { signal: controller.signal, headers: { Authorization: `Bearer ${token}` } })
      const json = await response.json();
      return { status: response.status, json }
    } catch (error) {
      return { error }
    }
  }

  // TO DO: implement POST, PUT, etc... methods as needed

  return { get }
}