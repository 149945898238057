import axios, { AxiosInstance } from 'axios';
import { refreshToken } from 'az-auth';
import clearSessionWithInfo from './utils/clearSessionWithInfo';

const axiosInstance = axios.create();
const retryAxiosInstance = axios.create();

if (process.env.NODE_ENV !== 'test') {
  const configureInterceptors = ({ instance, isRetry }: { instance: AxiosInstance; isRetry?: boolean }) => {
    instance.interceptors.response.use(
      // no need to intercept a successful response
      response => response,
      error => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
          if (!isRetry) {
            const refreshData = sessionStorage.getItem('refreshData');
            if (refreshData) {
              const refreshValues = JSON.parse(refreshData);
              return refreshToken(refreshValues.AUTH_ROOT, refreshValues.REDIRECT_URI, refreshValues.CLIENT_ID)
                .then(() => {
                  const loginDataStorage = sessionStorage.getItem('loginData');
                  if (loginDataStorage) {
                    const loginValues = JSON.parse(loginDataStorage);
                    originalRequest.headers.Authorization = `Bearer ${loginValues.access_token}`;
                  } else {
                    clearSessionWithInfo();
                  }
                })
                .catch(() => {
                  clearSessionWithInfo();
                })
                .then(() => retryAxiosInstance(originalRequest));
            }
          }
          return clearSessionWithInfo();
        }
        return Promise.reject(error);
      }
    );
  };
  configureInterceptors({ instance: axiosInstance });
  configureInterceptors({ instance: retryAxiosInstance, isRetry: true });
}

export default axiosInstance;
