import { refreshToken } from "az-auth";
import { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { STORE_RECALL_DATA, TOKEN_INFO } from "../constants";
import { sendLog } from "../redux/actions/log.actions";
import { createToast } from "../redux/actions/toasts.actions";
import { getConfigAuthValuesFromState } from "../redux/selectors";
import { AppLogType } from "../types";
import clearSessionWithInfo from "../utils/clearSessionWithInfo";

// this custom hook is used to add Single Sign On functionality to check token status on every focus change
export default function () {

    const dispatch = useDispatch();
    const { t } = useTranslation()
    const configAuthValues = useSelector(getConfigAuthValuesFromState);
    const checkToken = useRef<any>();

    // TODO: update to implement useFetch
    useEffect(() => {
        if (configAuthValues) {
            checkToken.current = async () => {
                let data = sessionStorage.getItem('loginData');
                let token = JSON.parse(data || "{}").access_token
                if (token) {
                    try {
                        const url = `${configAuthValues.API_ROOT}${TOKEN_INFO}`
                        let result = await fetch(url, { headers: { Authorization: `Bearer ${token}` } })
                        // token is not valid, attempt to get one one
                        // only explicit 401 is considered as unauthorized
                        if (result.status === 401) {
                            const refreshData = JSON.parse(sessionStorage.getItem('refreshData') || "{}");
                            if (refreshData) {
                                refreshToken(refreshData.AUTH_ROOT, refreshData.REDIRECT_URI, refreshData.CLIENT_ID).then(async () => {
                                    data = sessionStorage.getItem('loginData');
                                    token = JSON.parse(data || "{}").access_token
                                    if (token) {
                                        result = await fetch(url, { headers: { Authorization: `Bearer ${token}` } })
                                        // token was not refreshed, logout
                                        if (result.status === 401) {
                                            localStorage.removeItem(STORE_RECALL_DATA);
                                            clearSessionWithInfo();
                                        }
                                        // token correctly refreshed
                                        if (result.status === 200){
                                            dispatch(sendLog({ logEvent: AppLogType.SESSION_INFO, logMessage: "Access token refreshed"}));
                                        }
                                    }
                                    if (!token) {
                                        localStorage.removeItem(STORE_RECALL_DATA);
                                        clearSessionWithInfo();
                                    }
                                })
                            }
                        }
                    } catch (error) {
                        dispatch(createToast({ type: 'error', message: t('ERRORS.DEFAULT_ERROR_MESSAGE') }))
                    }
                }
                if (!token) {
                    localStorage.removeItem(STORE_RECALL_DATA);
                    clearSessionWithInfo();
                }
            }
            window.addEventListener('focus', checkToken.current);
        }
        return () => {
            window.removeEventListener('click', checkToken.current);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configAuthValues]);
}