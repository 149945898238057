module.exports = {
  CLIENT_ID: 'l7xxece0943cdb214fe4b3b0cbcfbda4b0a1',
  API_ROOT: 'https://dv9-api.autozone.com',
  AUTH_ROOT: 'https://dv9-api.autozone.com',
  AUTH_ROOT_MOBILE: 'https://dv-commercial-user-login-spa.apps.nonprod.mem.cloud.autozone.com/login/index.html',
  AUTH_SCOPE: 'az_mobile_sso',
  REDIRECT_URI: 'http://dev.autozone.com:3000',
  ENV: 'dv-ext',
  WHISPERS_URL: "https://dv9-api.autozone.com",
  SSO_DEVICES: [
    "CT40"
  ],
  INACTIVITY_TIMEOUT: 10,
  FETCH_TIMEOUT: 6000,
  INACTIVITY_TIMEOUT_FOR_TOASTS: 1000 * 60 * 2   // 2 mins timeout 
};
