import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_TYPE_COMMERCIAL, ORDER_TYPE_OVERSTOCK } from '../../../constants';
import { OrderType } from '../../../types';
import ItemQuantityBox from '../../layout/ItemQuantityBox';
import { mergeClassNames } from '../../../utils';

import styles from './DetailsViewCardHeader.css';

type Props = {
  keepQuantityOnHand: number | null;
  description: string;
  pickedQuantity: number;
  totalQuantity: number;
  hasJustScanned: boolean;
  orderType: OrderType;
  quantityOnHand: number | null;
};

const DetailsViewCardHeader = ({
  keepQuantityOnHand,
  description,
  pickedQuantity,
  totalQuantity,
  hasJustScanned,
  orderType,
  quantityOnHand,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.DETAILS_VIEW';
  const disableKeepQoH = orderType === ORDER_TYPE_COMMERCIAL || orderType === ORDER_TYPE_OVERSTOCK;
  return (
    <header className={styles.header}>
      <h6 className={styles.itemName} data-cy="pick-tour-item-description">
        {description}
      </h6>
      <div className={styles.itemQtyRow}>
        <div className={styles.keepQtyRow}>
          <span>
            <span>{t(`${translationRoot}.QOH_LABEL`)}</span>
            <span className={styles.amount}>{quantityOnHand}</span>
            <span className={mergeClassNames(disableKeepQoH ? styles.disabled : '', styles.keepLabel)}>
              {t(`${translationRoot}.KEEP_LABEL`)}
            </span>
            <span className={mergeClassNames(disableKeepQoH ? styles.disabled : '', styles.amount)}>
              {keepQuantityOnHand || 0}
            </span>
          </span>
        </div>
        <ItemQuantityBox
          pickedQuantity={pickedQuantity}
          totalQuantity={totalQuantity}
          hasJustScanned={hasJustScanned}
        />
      </div>
    </header>
  );
};

export default DetailsViewCardHeader;
